<template>
  <div class="lock-log">
    <div class="title">
      <app-header :title="title" :isShowBack="true"></app-header>
    </div>
    <el-row class="info">
      <el-col :span="24">
        <span class="txt">安装位置: {{apartmentName}}{{location}}</span>
        <span class="txt">MAC: {{mac}}</span>
        <span class="txt">设备端软件版本: {{firmwareVersion}}</span>
        <!-- <span class="txt">IP: {{roomIP}}</span> -->
        <!-- <span class="txt">设备状态: {{roomstatus}}</span> -->
      </el-col>
    </el-row>
    <app-list :opt="opt" @get="onGet" ref="list"></app-list>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      title: "借用记录",
      opt: {
        search: [
          {
            key: "date",
            label: "起止日期",
            timeType: "yyyy-MM-dd HH:mm:ss",
            format: "yyyy-MM-dd",
            type: "daterange"
          },
          {
            key: "validateType",
            label: "验证方式",
            type: "select",
            opt: {
              list: [
                {
                  label: "手机号+密码",
                  value: "1"
                },
                {
                  label: "手机号+验证码",
                  value: "2"
                }
              ]
            }
          }
        ],
        columns: [
          { label: "序号", key: "sequenceNumber", width: 80 },
          { label: "验证方式", key: "validationType" },
          { label: "开锁人", key: "unlockUserName" },
          { label: "手机号码", key: "phoneNoStr" },
          { label: "房源", key: "RoomName" },
          { label: "借用时间", key: "borrowTime" },
          // { label: "开锁时间", key: "unlockDate" },
          { label: "退还时间", key: "returnTime" }
        ]
      },
      row: {},
      id: "",
      apartmentName: "",
      location: "",
      mac: "",
      firmwareVersion: "",
      //   roomIP: ""
      //   roomstatus: ""
    };
  },
  activated() {
    console.log("lock-log activated!!");
    // this.row = this.cache.get("separateAccessControl");
    this.$route.params.id;
    this.$refs.list.onRefresh();
    this.getOrdeData(this.$route.params.id);
  },
  methods: {
    onGet(opt) {
      console.log(opt.searchForm);
      let dto = {
        pageNum: opt.skip,
        pageSize: opt.limit,
        gatewayId: this.$route.params.id,
        beginTime: opt.searchForm["date"] ? opt.searchForm["date"][0] : "",
        endTime: opt.searchForm["date"] ? opt.searchForm["date"][1] : "",
        validateType: opt.searchForm["validateType"]
          ? opt.searchForm["validateType"]
          : ""
      };
      this.post("/device-service/L2/pageQuery/borrowRecord", dto, {
        isUseResponse: true
      }).then(res => {
        for (let i = 0; i < res.data.data.length; i++) {
          let item = res.data.data[i];
          item.validationType = ["手机号+密码", "手机号+验证码"][
            item.validationType - 1
          ];
          item.sequenceNumber=opt.limit*(opt.skip-1)+i+1;
          item.borrowTime =
            item.borrowTime == null ? "" : this.format(item.borrowTime);
          // item.unlockDate =
          //   item.unlockDate == null ? "" : this.format(item.unlockDate);
          item.returnTime =
            item.returnTime == null ? "" : this.format(item.returnTime);
            item.RoomName =
            (item.apartmentName?item.apartmentName:'') + (item.buildNo?(item.buildNo + "栋"):'') + (item.roomNo? (item.roomNo+ "房"):'');
            if (this.cache.getLS("userInfo")["roleType"] != "8") {
            item.phoneNoStr=item.phoneNo
              ? item.phoneNo.substring(0, 3) +
                "****" +
                item.phoneNo.substring(7, item.phoneNo.length)
              : "";
            }
            else{
              item.phoneNoStr=item.phoneNo
            }

        }
        opt.cb(res.data);
      });
    },
    // 获取订单信息
    getOrdeData(roomId) {
      this.get("/device-service/L2/detail/" + roomId).then(res => {
        console.log(res);
        this.apartmentName = res.apartmentName;
        this.location = res.location;
        this.mac = res.mac;
        this.firmwareVersion = res.firmwareVersion;
        // this.roomIP = res.ip;
        // this.roomstatus = res.lockType;
      });
    }
  }
};
</script>
<style lang="scss">
.lock-log {
  .danger {
    color: #f56c6c;
  }
  .info1 {
    padding: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #606266;
    .txt {
      margin-right: 20px;
    }
  }
  .info {
    padding: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #606266;
    background-color: #e4e7ed;

    .txt {
      margin-right: 20px;
    }
  }
}
</style>
